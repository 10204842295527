:root {
    --mainColor: #0a0a0a;
    --mainColorHover: #121212;
    --mainContent: white;
    --headerContent: #0a0a0a;
    --mainColorDarker: #000000;
    --mainColorLighter: #121212;

    --bannerHeight: 440px;
    --bannerHeightMobile: 600px;
    --headerBg: white;
    --footerBg: #0a0a0a;
    --footerDarker: #000000;
    
    --secondColor: #4d4d4d;
    --secondColorHover: #595959;
    --secondContent: white;

    --fadedMainColor: #001E51a0;
    --veryFadedMainColor: #001E513f;

    --transparentWhite: rgba(255, 255, 255, 0.5);

    --highlightGreen: #00f58f;
    --highlightGreenHover: #10ff9b;

    --grayedText: #888888;
    --redText: red;
    --greenText: #00a130;
    --blueText: #0059ff;
    --yellowText: #ffcc00;
    --inputPlaceholderColor: #9E9E9E;

    --grayIcon: #b6b6b6;

    --veryVeryLightGray: #fafafa;
    --veryLightGray: #f5f5f5;
    --lightGray: #ececec;
    --focusBg: #eeeeee;
    --textColor: #333;
    --darkTextColor: #1f1f1f;
    --defaultTextSize: 14px;
    --headerHeight: 60px;
    --headerHeightMobile: 60px;
    /* --headerHeightMobile: 40px; */

    /* --giantButtonGradient: linear-gradient(to top right, #001438, #001E51);
    --giantButtonGradientHover: linear-gradient(to top right, #001E51, #042f79); */
    --giantButtonGradient: linear-gradient(to top right, #4d4d4d, #595959);
    --giantButtonGradientHover: linear-gradient(to top right, #595959, #6a6a6a);
    --giantButtonGradientContent: white;

    --hoverTranslateY: -4px;
    --hoverScale: 1.05;
    --hoverScaleLarge: 1.1;
    --hoverScaleSmall: 1.025;

    --productTileWidth: 300px;
    --productTileSmallWidth: 236px;
    --productTileWidthMobile: 100%;
    --productTileHeight: 420px;
    --productTileSmallHeight: 360px;
    --productTileHeightMobile: 480px;
    --productTileMargin: 10px;
    --productTileMarginMobile: 0px;

    --defaultElementHeight: 36px;

    --mediumRowGap: 14px;
    --smallRowGap: 10px;
    --tinyRowGap: 6px;
    --largeRowGap: 20px;
    
    --mediumPadding: 14px;

    --tinyBorderRadius: 2px;
    --smallBorderRadius: 3px;
    --mediumBorderRadius: 6px;
    --largeBorderRadius: 10px;

    --defaultMarginBottom: 40px;
    /* --defaultMarginBottom: 16vh; */

    --catTileWidth: 216px;
    --catTileHeight: 216px;
    --catTileMargin: 10px;

    --defaultDialogWidth: 640px;
    --defaultDialogHeight: 640px;

    --lightHighlightMark: #05D4F1;

    /* --defaultCardShadow: 0 0 6px rgba(0, 0, 0, 0.05); */
}